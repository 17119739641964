import { CREATE_CART, LOAD_CART } from "./cart-queries";

import client from "../apollo-client";

export const createCart = async () => {
  const {
    data: {
      cartCreate: { cart },
    },
    errors,
  } = await client.mutate({
    mutation: CREATE_CART,
  });

  if (errors) {
    throw new Error(errors[0].message);
  }

  return cart;
};

export const loadCart = async (cartId) => {
  const {
    data: { cart },
    errors,
  } = await client.query({
    query: LOAD_CART,
    variables: { cartId: cartId },
  });

  if (errors) {
    console.log(errors[0].message);
    return false;
  }

  return cart;
};
