import { gql } from "@apollo/client";

export const CREATE_CART = gql`
  mutation CreateCart {
    cartCreate {
      cart {
        checkoutUrl
        id
      }
    }
  }
`;

export const LOAD_CART = gql`
  query GetCart($cartId: ID!) {
    cart(id: $cartId) {
      checkoutUrl
      estimatedCost {
        totalAmount {
          amount
        }
      }
      lines(first: 100) {
        edges {
          node {
            id
            quantity
            estimatedCost {
              subtotalAmount {
                amount
              }
              totalAmount {
                amount
              }
            }
            merchandise {
              ... on ProductVariant {
                id
                product {
                  title
                  brand: vendor
                  featuredImage {
                    src
                    altText
                  }
                  handle
                }
                priceV2 {
                  amount
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ADD_TO_CART = gql`
  mutation AddToCart($cartId: ID!, $variantId: ID!) {
    cartLinesAdd(
      cartId: $cartId
      lines: [{ quantity: 1, merchandiseId: $variantId }]
    ) {
      cart {
        estimatedCost {
          totalAmount {
            amount
          }
        }
        lines(first: 100) {
          edges {
            node {
              id
              quantity
              estimatedCost {
                totalAmount {
                  amount
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  product {
                    title
                    brand: vendor
                    featuredImage {
                      src
                      altText
                    }
                    handle
                  }
                  priceV2 {
                    amount
                  }
                }
              }
            }
          }
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const REMOVE_FROM_CART = gql`
  mutation removeItemFromCart($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        estimatedCost {
          totalAmount {
            amount
          }
        }
        lines(first: 100) {
          edges {
            node {
              id
              quantity
              estimatedCost {
                totalAmount {
                  amount
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  product {
                    title
                    brand: vendor
                    featuredImage {
                      src
                      altText
                    }
                    handle
                  }
                  priceV2 {
                    amount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
