const breakpoints = ["640px", "960px", "1280px"];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];

export const theme = {
  breakpoints,
  colors: {
    darkGrey: "#30302F",
    green: "#01A847",
    mint: "#B5E0C7",
    yellow: "#FFEE99",
    lightYellow: "#FFFBA8",
    lilac: "#B5B6E0",
    lightGrey: "#D8D8D8",
    red: "#C71111",
    pink: "#FFBDBD",
    peach: "#FFD7BD",
    grey: "#D9DAD1",
    lightBlue: "#B5D1E0",
    menswear: "#B5D1E0",
    womenswear: "#B5B6E0",
    boys: "#B5D1E0",
    girls: "#B5B6E0",
    kids: "#B5E0C7",
    mushroom: "#EEEEEE",
  },
  fonts: {
    ibmPlexSans: "IBM Plex Sans, sans-serif",
    ibmPlexMono: "IBM Plex Mono, sans-serif",
  },
  capSizes: {
    ibmPlexSans: {
      height: 0.78,
      offset: -0.01,
    },
    ibmPlexMono: {
      height: 0.78,
      offset: 0,
    },
  },
  mediaQueries: {
    small: `@media screen and (min-width: ${breakpoints.sm})`,
    medium: `@media screen and (min-width: ${breakpoints.md})`,
    large: `@media screen and (min-width: ${breakpoints.lg})`,
  },
};
