import React, { createContext, useContext, useEffect, useState } from "react";

import { useRouter } from "next/router";

export const AppContext = createContext();
export const useAppContext = () => useContext(AppContext);

function AppContextProvider({ children }) {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [mobMainMenuOpen, setMainMenuOpen] = useState(false);
  const [mobBrandMenuOpen, setMobBrandMenuOpen] = useState(false);
  const [productImageOverlayOpen, setProductImageOverlayOpen] = useState(false);
  const [clickedImage, setClickedImage] = useState(null);
  const router = useRouter();

  useEffect(() => {
    setMainMenuOpen(false);
    setMobBrandMenuOpen(false);
  }, [router.asPath]);

  useEffect(() => {
    if (
      filterMenuOpen ||
      mobMainMenuOpen ||
      mobBrandMenuOpen ||
      productImageOverlayOpen
    ) {
      document.body.classList.add("overlay-open");
    } else {
      document.body.classList.remove("overlay-open");
    }
  }, [filterMenuOpen, mobMainMenuOpen, mobBrandMenuOpen]);

  return (
    <AppContext.Provider
      value={{
        filterMenuOpen,
        setFilterMenuOpen,
        mobMainMenuOpen,
        setMainMenuOpen,
        mobBrandMenuOpen,
        setMobBrandMenuOpen,
        productImageOverlayOpen,
        setProductImageOverlayOpen,
        clickedImage,
        setClickedImage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
