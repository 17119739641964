import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

export const ViewportContext = createContext();
export const useViewportContext = () => useContext(ViewportContext);

function ViewportContextProvider({ children }) {
  const [innerHeight, setInnerHeight] = useState(0);
  const [innerWidth, setInnerWidth] = useState(0);
  const [isTouch, setIsTouch] = useState(false);
  const widthRef = useRef(null);

  useEffect(() => {
    setInnerHeight(window.innerHeight);
    setInnerWidth(window.innerWidth);
    widthRef.current = window.innerWidth;

    // Touch
    if ("ontouchstart" in document.documentElement) {
      document.body.classList.add("is-touch");
      if (!isTouch) setIsTouch(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
      // Check if width has also changed to know if window has truly resized,
      // to prevent the resize happening on iOS scroll
      if (innerWidth !== widthRef.current) {
        setInnerHeight(window.innerHeight);
        widthRef.current = innerWidth;
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ViewportContext.Provider value={{ innerHeight, innerWidth, isTouch }}>
      {children}
    </ViewportContext.Provider>
  );
}

export default ViewportContextProvider;
