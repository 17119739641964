const config = {
  local: {
    CONTENT_API_BASE: "http://localhost:8001",
    ORIGIN: "http://localhost:3000",
    BFF_API_BASE: "http://localhost:8000",
    BFF_CDN: "http://localhost:8000",
    CLIENT_API_BASE: "http://localhost:8000",
  },
  staging: {
    CONTENT_API_BASE: "https://recommerce-cms-staging.herokuapp.com",
    ORIGIN: "https://staging.reskinned.clothing",
    BFF_API_BASE: "https://staging.bff.reskinned.clothing",
    BFF_CDN: "https://staging.bff.reskinned.clothing",
    CLIENT_API_BASE: "https://staging.client.reskinned.clothing",
  },
  production: {
    CONTENT_API_BASE: "https://recommerce-cms-production.herokuapp.com",
    ORIGIN: "https://www.reskinned.clothing",
    BFF_API_BASE: "https://bff.reskinned.clothing",
    BFF_CDN: "https://d3ncfv4nao639j.cloudfront.net",
    CLIENT_API_BASE: "https://client.reskinned.clothing",
  },
};

export default function getConfig(key) {
  const env = process.env.REACT_APP_ENVIRON || "local";
  return config[env][key];
}
